//const host = "https://expense-tracker-app-knl1.onrender.com";
const host = "https://finx.website:3000";
export const setAvatarAPI = `${host}/api/auth/setAvatar`;
export const registerAPI = `${host}/api/auth/register`;
export const loginAPI = `${host}/api/auth/login`;
export const verifyEmailAPI = `${host}/api/auth/verify-email`;
export const passwordRestRequestAPI = `${host}/api/auth/forgot-password`;
export const passwordRestAPI = `${host}/api/auth/reset-password`;
export const profileAPI = `${host}/api/auth/user`;
export const addTransaction = `${host}/api/v1/addTransaction`;
export const getTransactions = `${host}/api/v1/getTransaction`;
export const editTransactions = `${host}/api/v1/updateTransaction`;
export const deleteTransactions = `${host}/api/v1/deleteTransaction`
